export default {
  "form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle eine personalisierte Geschichte: "])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spitzname des Kindes"])},
    "nickname_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Spitznamen des Kindes ein"])},
    "checkbox_sister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte, dass die Geschichte den Bruder/die Schwester enthält"])},
    "sister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spitzname Bruder/Schwester"])},
    "sister_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie den Spitznamen des Bruders/der Schwester"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle Alter"])},
    "age_dropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])},
    "needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Bedürfnis aus"])},
    "anxiety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angst"])},
    "addiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sucht"])},
    "timidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüchternheit"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufmerksamkeit"])},
    "arguing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruder/Schwester-Kämpfe"])},
    "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle eine Geschichte für"])},
    "loading_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich schreibe die Geschichte..."])},
    "form_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie das Formular aus"])}
  }
}