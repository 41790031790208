export default {
  "form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creează o poveste personalizată: "])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenumele copilului"])},
    "nickname_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdu prenumele (porecla) copilului"])},
    "checkbox_sister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vreau ca sora sau fratele sa fie inclus(ă) în poveste (optional)"])},
    "sister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenume soră/frate:"])},
    "sister_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introdu prenumele (porecla) copilului"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alege Vârsta"])},
    "age_dropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ani"])},
    "needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alege o nevoie"])},
    "anxiety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anxietate"])},
    "addiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dependenta"])},
    "timidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timiditate"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atentie"])},
    "arguing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certuri cu sora/fratele"])},
    "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creează o poveste pentru"])},
    "loading_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se creează povestea..."])},
    "form_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completează formularul"])}
  }
}