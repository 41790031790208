export default {
  "form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una historia personalizable: "])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apodo del niño"])},
    "nickname_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa el apodo del niño"])},
    "checkbox_sister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiero que la historia incluya a la hermana/hermano"])},
    "sister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apodo de la hermana/hermano"])},
    "sister_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige el apodo de la hermana/hermano"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige la edad"])},
    "age_dropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["años"])},
    "needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige una necesidad"])},
    "anxiety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ansiedad"])},
    "addiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adicción"])},
    "timidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timidez"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atención"])},
    "arguing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peleas entre hermanos"])},
    "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una historia para"])},
    "loading_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy escribiendo la historia..."])},
    "form_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor rellena el formulario"])}
  }
}