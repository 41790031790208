<template >
  <section class="container-fluid">
    <h2 class="text-center display-6 mt-2">{{ $t('form.title') }} </h2>
    <img src="../assets/openai.svg" class="mx-auto d-block  mt-0" alt="OpenAI logo" width="32" height="32">
    <p class="text-center text-muted p-0 m-0 mb-3 lead" style="font-size: 14px;">powered by <i>OpenAI</i></p>

    <div class="d-flex align-items-start justify-content-center">
      <div class="col-lg-6 col-md-10 col-12 align-self-center">

        <!-- Language Switcher START -->
        <div class="d-flex justify-content-end align-items-end text-muted">
          Language &nbsp;
          <select v-model="$i18n.locale" @change="languageChange($event)">
            <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
              {{ locale }}
            </option>
          </select>
        </div>
        <!-- Language Switcher END -->

        <form action="POST" class="" @submit.prevent>

          <div class=" mb-3">
            <label for="exampleInputEmail1" class="form-label"> {{ $t('form.nickname') }}:</label>
            <input type="text" class="form-control" :placeholder="$t('form.nickname_placeholder')" aria-label="Username"
              aria-describedby="basic-addon1"
              @input="event => this.personaj.nume = (event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))">
          </div>

          <div class="form-check">
            <input v-model="this.personaj.sora" v-once class="form-check-input" type="checkbox" value=""
              id="flexCheckChecked">
            <label class="form-check-label" for="flexCheckChecked">
              {{ $t('form.checkbox_sister') }}
              <!-- Vreau ca sora sau fratele sa fie inclus(ă) în poveste -->
            </label>
          </div>

          <div v-if="this.personaj.sora !== false" class="container input-group mb-3 ms-3 ">
            <span class="input-group-text" id="basic-addon1">
              <!-- Prenume soră/frate: -->
              {{ $t('form.sister') }}
            </span>
            <input
              @input="event => this.personaj.sora = (event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1))"
              type="text" class="form-control" :placeholder="$t('form.sister_placeholder')" aria-label="Username"
              aria-describedby="basic-addon1">
          </div>

          <select v-model="this.personaj.varsta" class="form-select my-3" aria-label="Default select example">
            <option disabled hidden value="">
              <!-- Alege vârsta -->
              {{ $t('form.age') }}
            </option>
            <option value="5-8">5-8 {{ $t('form.age_dropdown') }}</option>
            <option value="9-12">9-12 {{ $t('form.age_dropdown') }}</option>
            <option value="12-16">12-16 {{ $t('form.age_dropdown') }}</option>
          </select>

          <select v-model="this.personaj.poveste" class="form-select my-3" aria-label="Default select example">
            <option disabled hidden value="">{{ $t('form.needs') }}</option>
            <option value="anxietate">{{ $t('form.anxiety') }}</option>
            <option value="dependente">{{ $t('form.addiction') }}</option>
            <option value="timiditate"> {{ $t('form.timidity') }} </option>
            <option value="atentie"> {{ $t('form.attention') }} </option>
            <option value="cearta cu fratele sau sora sa"> {{ $t('form.arguing') }} </option>
          </select>

        </form>

        <div class="text-center"
          v-if="this.personaj.nume === '' || this.personaj.varsta === '' || this.personaj.poveste === ''">
          <button class="btn btn-danger btn-lg"> {{ $t('form.form_incomplete') }}</button>
        </div>

        <div class="text-center"
          v-if="!this.loading && !(this.personaj.name === '' || this.personaj.varsta === '' || this.personaj.poveste === '')">
          <button class="btn btn-success btn-lg" @click="generateStory()">{{ $t('form.submit_button') }} {{
            this.personaj.nume
          }} </button>
        </div>

        <div class="text-center" v-if="this.loading">
          <button class="btn btn-success btn-lg disabled">{{ $t('form.submit_button') }} {{
            this.personaj.nume
          }} <span class="spinner-border text-light" role="status">
              <span class="sr-only"></span>
            </span> </button>
        </div>

      </div>
    </div>

    <div v-if="this.povesteFinala !== ''" class="container mt-5 col-lg-8 col-md-10 col-12 lead p-4"
      style="font-weight: 350; background-color: #FFFFED; border-radius: 15px; border-color: #D9D99B; border-style: dotted;">
      {{ this.povesteFinala }}
    </div>

    <img class="my-3 img-fluid rounded mx-auto d-block" v-if="this.imageUrl !== ''" :src="this.imageUrl"
      alt="First Story Image">

  </section>
</template>

<script>

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      URL_TEXT_API: "https://ai.booklab.ro/api/text.php",
      URL_IMAGE_API: "https://ai.booklab.ro/api/image.php",

      locales: ["ro", "en", "de", "es"],

      personaj: {
        nume: "",
        varsta: "",
        sora: false,
        poveste: "",
        limba: "ro"
      },
      loading: false,
      povesteFinala: '',
      imageUrl: '',
      povesteSaved: "Radu era un băiețel de 8 ani foarte drăguț și îndrăgit de toată lumea. El era mereu fericit și își petrecea timpul jucându-se și vorbind cu prietenii săi. Într-o zi, Radu a început să se simtă ciudat. El nu se simțea la fel de fericit ca de obicei. El devenea foarte anxios și se simțea foarte stresat. Radu și-a început să se îngrijoreze și să se teamă că ceva rău se va întâmpla. El a început să se teamă că toți prietenii săi vor dispărea și că el nu va mai putea face față vieții. Radu și-a spus că trebuie să facă ceva pentru a se simți mai bine. El și-a amintit că mama sa îi spusese că atunci când se simte anxios, trebuie să facă câteva lucruri simple pentru a se relaxa. Primul lucru pe care Radu l-a făcut a fost să ia o respirație adâncă și să se concentreze asupra respirației sale. El și-a dat seama că respirația sa se încetinește și se simte mai relaxat. Apoi, Radu a început să-și amintească lucrurile pe care le iubea. El și-a amintit că îi place să se joace cu prietenii săi, să se joace cu animalele sale de casă și să se uite la desene animate. Acest lucru l-a ajutat să se simtă mai relaxat și să se bucure de momentele prezente. În cele din urmă, Radu a încercat să se gândească la lucrurile pozitive din viața lui. El și-a amintit cât de norocos era că avea o familie minunată și că avea mulți prieteni care îl iubeau și îl susțineau. Acest lucru l-a ajutat să se simtă mai bine și să-și ia înapoi controlul asupra anxietății sale. Radu a învățat că anxietatea poate fi gestionată prin practicarea respirației adânci, prin amintirea lucrurilor pe care le iubește și prin gândirea pozitivă. El a învățat că este important să-și amintească cât de norocos este și cât de mult îl iubesc cei din jurul lui.",
    }
  },
  methods:
  {
    async generateStory() {

      this.personaj.limba = this.$root.$i18n.locale;
      this.loading = true;

      const response = await fetch(this.URL_TEXT_API, {
        method: 'POST',
        caches: 'no-cache',
        body: JSON.stringify(this.personaj)
      });

      console.log(this.personaj);

      try {
        this.povesteFinala = ''; // reset
        var data = await response.text();
        this.typeText(data, 20, 0); // simuleaza scris incet
        console.log(data);
        this.generateImage(data); // genereaza imaginile pentru story

      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },

    async generateImage(povesteFinala) {

      console.log('obj');
      console.log({ poveste: povesteFinala, index: 0 });
      const imageResponse = await fetch(this.URL_IMAGE_API, {
        method: 'POST',
        caches: 'no-cache',
        body: JSON.stringify({ poveste: povesteFinala, index: 1 })
      });

      try {
        var data = await imageResponse.text();
        this.imageUrl = data;
        console.log(data);

      } catch (error) {
        console.log(error);
      }
    },

    typeText(text, delay, i) {
      setTimeout(() => {
        if (i < text.length) {
          i++;
          this.povesteFinala += text.charAt(i);
          this.typeText(text, delay, i);
        }
      }, delay)
    },
    languageChange(event) {
      this.personaj.limba = event.target.value;
    },
    checkLanguageURL() // check if url contains language string
    {
      //match the url language with language '/en, /ro, ...'
      if (window.location.href.includes('en'))
        this.$root.$i18n.locale = 'en';

      if (window.location.href.includes('ro'))
        this.$root.$i18n.locale = 'ro';

      if (window.location.href.includes('de'))
        this.$root.$i18n.locale = 'de';

      if (window.location.href.includes('es'))
        this.$root.$i18n.locale = 'es';

      this.personaj.limba = this.$root.$i18n.locale;
    }
  },

  mounted() {
    if (process.env.NODE_ENV === 'development') {
      this.URL_TEXT_API = "http://localhost/ai_ChatGPT/API/text.php";
      this.URL_IMAGE_API = "http://localhost/ai_ChatGPT/API/image.php";
    }

    this.checkLanguageURL();

  }

}
</script>
<style>
.col-lg-6,
.col-md-10,
.col-12 {
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
</style>