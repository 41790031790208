export default {
  "form": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a customizable story: "])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kid's nickname"])},
    "nickname_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the kid's nickname"])},
    "checkbox_sister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want the story to include the sister/brother (optional)"])},
    "sister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname sister/brother"])},
    "sister_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the sister/brother nickname"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Age"])},
    "age_dropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a need"])},
    "anxiety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anxiety"])},
    "addiction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addiction"])},
    "timidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["timiditate"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attention"])},
    "arguing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brother/sister fights"])},
    "submit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a story for"])},
    "loading_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am writing the story..."])},
    "form_incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill out the form"])}
  }
}